/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  /* --backgroundLocalTeam: #0d3aff;
  --backgroundVisitorTeam: #0d3aff; */

  /** primary **/
  --ion-color-primary: #0d3aff;
  --ion-color-primary-rgb: 240, 101, 47;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0929c9;
  --ion-color-primary-tint: #061da5;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  --ion-color-secondary: #69bb7b;
	--ion-color-secondary-rgb: 105,187,123;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #5ca56c;
	--ion-color-secondary-tint: #78c288;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;





  --ion-background-color: #262626;
	--ion-background-color-rgb: 38,38,38;

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255,255,255;

	--ion-color-step-50: #313131;
	--ion-color-step-100: #3c3c3c;
	--ion-color-step-150: #474747;
	--ion-color-step-200: #515151;
	--ion-color-step-250: #5c5c5c;
	--ion-color-step-300: #676767;
	--ion-color-step-350: #727272;
	--ion-color-step-400: #7d7d7d;
	--ion-color-step-450: #888888;
	--ion-color-step-500: #939393;
	--ion-color-step-550: #9d9d9d;
	--ion-color-step-600: #a8a8a8;
	--ion-color-step-650: #b3b3b3;
	--ion-color-step-700: #bebebe;
	--ion-color-step-750: #c9c9c9;
	--ion-color-step-800: #d4d4d4;
	--ion-color-step-850: #dedede;
	--ion-color-step-900: #e9e9e9;
	--ion-color-step-950: #f4f4f4;


  


}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #0769a5;
  --ion-color-tint: #1da1f4;
}

.ion-color-nul {
	--ion-color-base: #313131;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-seconde {
	--ion-color-base: #3c3c3c;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-271, .ion-color-501 {
	--ion-color-base: #0d3aff;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-0 {
	--ion-color-base: #ff560d;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-1 {
	--ion-color-base: #eb445a;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-2 {
	--ion-color-base: #ed576b;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-3 {
	--ion-color-base: #ff9e0d;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-4 {
	--ion-color-base: #ffba0d;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-5 {
	--ion-color-base: #fee8b1;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-6 {
	--ion-color-base: #dbfeb1;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-7 {
	--ion-color-base: #99d685;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-8 {
	--ion-color-base: #1ace1a;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-9 {
	--ion-color-base: #19f401;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-10 {
	--ion-color-base: #247b07;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}


.ion-color-local-team {
	--ion-color-base: var(--backgroundLocalTeam);
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-visitor-team {
	--ion-color-base: var(--backgroundVisitorTeam);
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}


ion-badge {
  --background: purple;
  --color: white;
  /* --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px; */
  width: 32px;
  height: 32px;
  --padding-top: 10px;
}


